import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Likes from "../likes";
import Auctions_dropdown from "../dropdown/Auctions_dropdown";
import { useDispatch, useSelector } from "react-redux";
import { buyModalShow, confirmationModalShow } from "../../redux/counterSlice";
import ConfirmationModal from "../../pages/collection/confirmationModal";

const CategoryItem = ({ collectionData, }) => {
  const { sortedtrendingCategoryItemData } = useSelector(
    (state) => state.counter
  );
  const dispatch = useDispatch();
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
        {collectionData?.map((collection) => {
          return collection?.artifacts?.map((item) => {
            const {
              id,
              image,
              name: title,
              price,
              bidLimit,
              bidCount,
              likes,
              creator,
              owner,
            } = item;

            return (
              <article key={id}>
                <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                  <figure className="relative">
                    <Link
                      href={`/artifact/${item?.id}`}
                    >
                      <img
                        width={230}
                        height={230}
                        src={`${process.env.NEXT_PUBLIC_AWS_URL}/${item?.image}`}
                        alt={item?.title}
                        className="w-full h-[230px] rounded-[0.625rem] object-cover"
                      />
                    </Link>

                  </figure>
                  <div className="mt-7 flex items-center justify-between">
                    <Link href={`/artifact/${id}`}>
                      <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                        {title}
                      </span>
                    </Link>

                    {/* Add Auctions_dropdown component here */}
                  </div>
                  <div className="mt-2 text-sm">
                    <span className="dark:text-jacarta-200 text-jacarta-700 mr-1">
                      {item?.price}
                    </span>
                    <span className="dark:text-jacarta-300 text-jacarta-500">
                      {collectionData[0]?.network?.name?.toUpperCase()}
                    </span>
                  </div>

                  <div className="mt-8 flex items-center justify-between">
                    <div
                      href={`/artifact/${id}`}
                      className="text-accent font-display text-sm font-semibold cursor-pointer"
                      // onClick={() => dispatch(buyModalShow())}
                      onClick={() => dispatch(confirmationModalShow())}
                    >
                      Mint now
                    </div>
                    <Link href={`/artifact/${id}`} className="group flex items-center">
                      <svg className="icon icon-history group-hover:fill-accent dark:fill-jacarta-200 fill-jacarta-500 mr-1 mb-[3px] h-4 w-4">
                        <use xlinkHref="/icons.svg#icon-history"></use>
                      </svg>
                      <span className="group-hover:text-accent font-display dark:text-jacarta-200 text-sm font-semibold">
                        View History
                      </span>
                    </Link>
                  </div>
                </div>
              </article>
            );
          });
        })}
      </div>
      {/* {
        visibleModal &&
        <ConfirmationModal />
      } */}
    </>
  );
};

export default CategoryItem;
